import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { loadClient } from '../../utilities/clients';

import './B2CWorkingHoursConfig.css';
import WorkingHoursConfig from '../WorkingHoursConfig/WorkingHoursConfig';
import PageSpinner from '../PageSpinner/PageSpinner';

const B2CWorkingHoursConfig = () => {
  const { id } = useParams();

  const [client, setClient] = useState({});

  useEffect(() => {
    loadClient(id).then(setClient);
  }, [id]);

  if (!client || !client.ID) return <PageSpinner></PageSpinner>;

  return <WorkingHoursConfig client={client}></WorkingHoursConfig>;
};

export default B2CWorkingHoursConfig;
